/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Marcellus&display=swap');


// fonts
$base-font-size: 15;
$base-font: "Inter", sans-serif;
$heading-font: "Marcellus", serif;
$heading-font-2:"Marcellus", serif;


// color
$dark-gray: #fff;
$body-color: #E5E5E5;
$white: #fff;
$light: #888;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #C4EF17;
$theme-primary-color-s2: #FFE600;
$theme-primary-color-s3: #DD584F;
$bg-color:#131313;
$bg-color2:#222121;
$bg-color3:#191919;
$body-bg-color: #1B1C1E;
$section-bg-color: #fafafa;
$text-color: #585858;
$text-light-color: #7b7b7b;
$heading-color: $dark-gray;
$border-color: #3e3b3b;
$border-color-s2: #e4ebf2;
/*--------------------------------------------------------------
8. Home-style-5
--------------------------------------------------------------*/


/*8.1 feature-section */
.feature-section {
    background: url(../../images/feature/bg-img.jpg) center / cover no-repeat;
    padding: 100px 0 70px;
}

.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .icon {}

    .content {
        width: calc(100% - 100px);
        margin-left: 20px;
        padding-left: 20px;
        position: relative;

        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 60px;
            content: '';
            background: #6F6F6F;
            transform: translateY(-50%);
        }

        h2 {
            color: $theme-primary-color;
            font-family: $base-font;
            font-size: 22px;
            font-weight: 400;
            line-height: 30px;
        }

        .low {
            transform: translateY(100%);
        }

        h5 {
            color: #FFF;
            font-size: 35px;
            font-weight: 400;
            margin-bottom: 0;

            @media (max-width:1399px) {
                font-size: 30px;
            }

            @media (max-width:1199px) {
                font-size: 25px;
            }
        }
    }
}

/*8.2 about-section-s5 */
.about-section-s5,.about-section-s6 {


    .about-right {
        margin-left: -180px;

        @media (max-width:991px) {
            margin-left: 0;
        }

        .image {
            border-radius: 642px;
            border: 1px solid #C4EF17;
            max-width: 642px;
            max-height: 642px;
            padding: 35px;

            @media (max-width:767px) {
                padding: 20px;
            }

            img {
                width: 100%;
                border-radius: 673px;
            }
        }
    }

    .section-top-content {
        margin-bottom: 0;
    }

}

/*8.3 portfolio-section-s4 */
.portfolio-section-s4 {

    .protfolio-card {
        position: relative;


        .text {
            opacity: 0;
            visibility: hidden;
            transform: translateX(-50%);
            width: 94%;
            text-align: center;
            position: absolute;
            left: 50%;
            bottom: 0px;
            transition: all 1s ease-in-out;

            h2{
                a{
                    @media (max-width:575px) {
                        font-size: 20px;
                    }
                }
            }

        }

        &:hover {
            .text {
                opacity: 1;
                visibility: visible;
                bottom: 20px;

            }
        }


    }
}
/*--------------------------------------------------------------
4. footer
--------------------------------------------------------------*/
.wpo-site-footer{
	position: relative;
    z-index: 1;
	padding: 100px 0;

	@media(max-width:991px){
		padding: 70px 0;
	}
    .shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .shape-start {
        position: absolute;
        left: 150px;
        top: 30%;
        z-index: -1;
        animation: roted 25s linear infinite;

        @media (max-width:767px) {
            display: none;
        }
    }

    .shape-2 {
        position: absolute;
        right: 0;
        bottom: -10%;
        z-index: -1;
    }

    .shape-start-2 {
        position: absolute;
        right: 200px;
        bottom: 50px;
        z-index: -1;
        animation: roted 25s linear infinite;

        @media (max-width:991px) {
            display: none;
        }
    }
	.wpo-footer-text{
		text-align: center;

		.logo{
			margin-bottom: 30px;
		}

		h2{
			margin-bottom: 30px;
			font-size: 50px;

			@media(max-width:991px){
                font-size: 30px;
			}
		}
	}
}


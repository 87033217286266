/*---------------------------
	Fonts
----------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Marcellus&display=swap');





// fonts
$base-font-size: 15px;
$base-font: "Inter", sans-serif;
$heading-font: "Marcellus", serif;



// color
$dark-gray: #fff;//
$body-color: #E5E5E5;
$white: #fff;//
$black: #000;//
$cyan: #CCCCCC;//

$theme-primary-color:#C4EF17;//
$body-bg-color: #1B1C1E;//
$text-color: #ADADAD;//
$heading-color: $dark-gray;
$border-color: #38393C;//
$border-color-s2: #485A00;//













